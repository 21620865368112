<template>
  <v-container fluid>
    <div class="fill-height pr-2">
      <h1 class="text-h4">
        {{ $lang.header.globalSettings }}
      </h1>
      <v-divider class="my-2"></v-divider>
      <v-row wrap no-gutters class="pb-2 fill-height">
        <v-col
          cols="12"
          sm="12"
          md="8"
          class="fill-height"
        >
          <v-card class="pa-2 fill-height">
            <v-form
              ref="form"
              v-model="valid"
              class="pt-3"
            >
              <v-row wrap no-gutters justify="space-between" class="pb-1">
                <v-col
                  cols="12"
                >
                  <h3>{{ setting && setting.type ? $lang.status[setting.type] : '' }}</h3>
                  <p class="text--secondary">{{ formatHint }}</p>
                </v-col>
                <v-col
                  cols="12"
                >
                  <values
                    v-if="!initLoading"
                    :type="setting.type"
                    :is-edit="isEdit"
                    :can-edit="userCanEdit"
                    :data="setting.values"
                    @setValues="setting.values = $event"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters wrap justify="space-between">
                <v-col
                  cols="6"
                  class="text-left"
                >
                  <div class="d-inline-flex align-center">
                    <v-btn
                      class="button-default-width"
                      color="secondary"
                      :to="{ name: 'globalSettings', params: { lang: $lang.current_lang } }"
                    >
                      <v-icon
                        right
                        dark
                        class="ma-0"
                      >
                        mdi-arrow-left
                      </v-icon>
                      <span class="ml-1">{{ $lang.actions.back }}</span>
                    </v-btn>
                  </div>
                </v-col>
                <v-col
                  cols="6"
                  class="text-right"
                >
                  <v-btn
                    :disabled="!valid || !setting.values || lock || !userCanEdit || Object.keys(setting.values).length === 0"
                    color="success"
                    class="button-default-width ml-2"
                    @click="submit()"
                  >
                    <v-icon
                      left
                      dark
                      class="mr-1"
                    >
                      mdi mdi-floppy
                    </v-icon>
                    {{ $lang.actions.submit }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import {
  getGlobalSettingByIdUsingGET as getSetting,
  updateProcessingGlobalSettingUsingPUT as updateInstanceProcessing,
  updateFrontendGlobalSettingUsingPUT as updateFrontend

} from '@/utils/api'
import { definitions } from '@/utils/definitions'
import Values from './Values.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    Values
  },
  data() {
    return {
      err: '',
      success: '',
      valid: false,
      isEdit: true,
      loading: false,
      initLoading: true,
      lock: false,
      setting: {
        type: '',
        id: 0,
        modifiedOn: '',
        values: null
      },
      userCanEdit: false,
      isSuperUser: false,
      types: definitions.GlobalSetting.properties.type.enum
    }
  },
  computed: {
    formatHint() {
      return `${this.$lang.labels.modifiedOn}: ${this.$options.filters.formatDateTime(this.setting.modifiedOn)}, ID: ${this.setting.id}`
    },
    formattedTypes() {
      return this.types.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    }
  },
  created() {
    this.isEdit = this.$route.name === 'settingEdit'

    let user = null

    if (localStorage.userData) {
      user = JSON.parse(localStorage.userData)

      this.isSuperUser = !!user.roles.find((x) => x.name === 'SUPER_USER')
    }

    if (this.$route.params.id) {
      this.loading = true
      getSetting({ id: this.$route.params.id })
        .then((res) => {
          this.setting = res.data.data

          this.userCanEdit = true

          this.loading = false
          this.initLoading = false
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),

    async submit() {
      this.lock = true

      let res = null

      try {

        switch (this.setting.type) {
        case 'PROCESSING':
          res = await updateInstanceProcessing({ body: this.setting })
          break
        case 'ADMIN':
          // TBD
          break
        case 'FRONTEND':
          res = await updateFrontend({ body: this.setting })
          break
        default:
          res = null
        }

        if (res && res.status !== 200) {
          const errorMessage = this.isEdit ? this.$lang.errors.settingUpdate : this.$lang.errors.settingCreate

          this.addSnackbar({
            message: errorMessage,
            timeout: 5000,
            color: 'error'
          })
          this.lock = false

          return
        }
        this.addSnackbar({
          message: this.$lang.success.settingUpdated,
          timeout: 5000,
          color: 'success'
        })
        this.lock = false

      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })      
        this.lock = false
      }
    }
  }
}
</script>

<style>
.copy-name-icon .v-icon{
  color: #009fb7;
}
</style>
